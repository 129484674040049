import { Card, Row, Col, Image, Typography, Tag, Tooltip, Button } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { shortenAddress, toFormat } from "../../lib/util";

const { Text } = Typography;

const statusColors = {
  active: "green",
  main: "green",
  inactive: "red",
  pending: "orange",
};

export default function ValidatorCards({ validators }) {
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log("Address copied:", text);
    });
  };

  return (
    <>
      <Row gutter={[16, 16]} justify="center">
        {validators.map((item) => (
          <Col xs={24} sm={12} md={8} lg={6} key={item.validatorAddress}>
            <Card
              hoverable
              style={{
                textAlign: "center",
                borderRadius: "10px",
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.05)",
                padding: "12px",
              }}
              cover={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "10px",
                  }}
                >
                  {item.logoUrl ? (
                    <Image
                      src={item.logoUrl}
                      alt="Validator Logo"
                      style={{
                        maxHeight: "60px",
                        objectFit: "contain",
                      }}
                      preview={false}
                    />
                  ) : (
                    ""
                  )}
                </div>
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                  fontSize: "12px",
                  color: "#666",
                }}
              >
                <Text strong style={{ fontSize: "14px" }}>
                  {item?.name || ""}{" "}
                </Text>
                {shortenAddress(item.validatorAddress)}
                <Tooltip title="Copy Address">
                  <Button
                    type="text"
                    icon={<CopyOutlined />}
                    size="small"
                    onClick={() => copyToClipboard(item.validatorAddress)}
                  />
                </Tooltip>
              </div>

              <Row justify="center" gutter={8} style={{ marginBottom: "8px" }}>
                <Col>
                  {item.status ? (
                    <Tag
                      color={statusColors[item?.status?.toLowerCase()] || "red"}
                    >
                      {item.status}
                    </Tag>
                  ) : (
                    ""
                  )}
                </Col>
                <Col>
                  {item.type ? (
                    <Tag
                      color={statusColors[item.type?.toLowerCase()] || "red"}
                    >
                      {item.type}
                    </Tag>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>

              <div
                style={{ fontSize: "12px", color: "#666", textAlign: "left" }}
              >
                <p>
                  <Text strong>Delegated:</Text>{" "}
                  {toFormat(item.delegatedAmount)}
                </p>
                <p>
                  <Text strong>Reward:</Text> {toFormat(item.rewards)}
                </p>
                <p>
                  <Text strong>Unclaimed:</Text>{" "}
                  {toFormat(item.unClaimedRewards)}
                </p>
                <p>
                  <Text strong>Pending:</Text> {toFormat(item.pendingReward)}
                </p>
                <p>
                  <Text strong>APR(%):</Text>{" "}
                  {item.apr ? Number(item.apr).toFixed(2) : "-"}
                </p>
              </div>

              <a
                href={`https://scan.chiliz.com/address/${item.validatorAddress}`}
                target="_blank"
                rel="noreferrer"
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: "#1890ff",
                  display: "inline-block",
                  marginTop: "8px",
                }}
              >
                See on Explorer
              </a>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
}
