export async function fetchChzPrice() {
  const url =
    "https://api.coingecko.com/api/v3/simple/price?ids=chiliz&vs_currencies=usd";

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Could not fetch chz price");
    }
    const data = await response.json();
    const chzPrice = data.chiliz.usd;

    return chzPrice;
  } catch (error) {
    console.error("Hata:", error.message);
  }
}


