import Web3 from "web3";
import { toHex } from "web3-utils";
import stakingAbi from "../lib/abis/staking.abi.json";
import { toHumanReadable, uniqueArray } from "../lib//util";
import BigNumber from "bignumber.js";

let web3, stakingContract;

const stakingAddress = "0x0000000000000000000000000000000000001000";
const blockOfEpoch = 28800;
const claimGap = 72000;

export const getDelegatorData = async (address, nodeUrl) => {
  web3 = new Web3(nodeUrl || "https://rpc.chiliz.com");
  stakingContract = new web3.eth.Contract(stakingAbi, stakingAddress);

  const [lastBlockNumber, validators, validatorsInfo] = await Promise.all([
    getLastBlockNumber(),
    stakingContract.methods.getValidators().call(),
    getValidatorsInfo(),
  ]);

  const remainingBlocks =
    blockOfEpoch - (Number(lastBlockNumber) % blockOfEpoch);

  const uniqueValidators = uniqueArray([
    ...validators.map((v) => v.toLowerCase()),
    ...validatorsInfo.map((v) => v.validator.toLowerCase()),
  ]);

  const [delegationInfo, transactions] = await Promise.all([
    getDelegatorInfo(uniqueValidators, address, validatorsInfo),
    getDelegatorTransactions(address),
  ]);

  const { expectedAnnualReward, expectedDailyReward } =
    calculateExpectedReward(delegationInfo);

  delegationInfo.expectedAnnualReward = expectedAnnualReward;
  delegationInfo.expectedDailyReward = expectedDailyReward;
  delegationInfo.lastBlockNumber = Number(lastBlockNumber);
  delegationInfo.remainingBlocks = `${remainingBlocks} / ${blockOfEpoch}`;
  delegationInfo.transactions = transactions;

  console.log("-----------------------------------------");
  console.log(delegationInfo);
  console.log("-----------------------------------------");

  return delegationInfo;
};

export const calculateExpectedReward = (delegationInfo) => {
  let expectedAnnualReward = 0;
  let expectedDailyReward = 0;
  const { validators } = delegationInfo;

  for (let i = 0; i < validators.length; i++) {
    const validator = validators[i];

    if (validator.apr && Number(validator.apr) !== 0) {
      const annualEarning =
        (validator.delegatedAmount * Number(validator.apr)) / 100;

      const dailyEarning = annualEarning / 383;

      expectedAnnualReward += annualEarning;
      expectedDailyReward += dailyEarning;
    }
  }

  return {
    expectedAnnualReward,
    expectedDailyReward,
  };
};

export const getDelegatorInfo = async (
  validators,
  delegator,
  validatorsInfo
) => {
  const delegationInfo = {
    totalDelegated: BigNumber(0),
    totalPendingReward: BigNumber(0),
    totalReward: BigNumber(0),
    currentEpoch: (await getCurrentEpoch()).toString(),
    validators: [],
    transactions: [],
  };

  for (const validator of validators) {
    const validatorDelegationInfo = await getDelegationInfo({
      validator,
      delegator,
    });

    const validatorInfo = validatorsInfo.find(
      (v) => v.validator.toLowerCase() === validator
    );

    if (
      Number(validatorDelegationInfo.delegatedAmount) !== 0 ||
      Number(validatorDelegationInfo.rewards) !== 0 ||
      Number(validatorDelegationInfo.unClaimedRewards) !== 0 ||
      Number(validatorDelegationInfo.pendingReward) !== 0
    ) {
      delegationInfo.validators.push({
        validatorAddress: validator,
        name: validatorInfo?.name,
        status: validatorInfo?.status,
        apr: validatorInfo?.apr,
        type: validatorInfo?.type,
        logoUrl: validatorInfo?.logoUrl,
        ...validatorDelegationInfo,
      });

      delegationInfo.totalDelegated = BigNumber(
        delegationInfo.totalDelegated.plus(
          validatorDelegationInfo.delegatedAmount
        )
      );
      delegationInfo.totalReward = BigNumber(
        delegationInfo.totalReward.plus(validatorDelegationInfo.rewards)
      );

      delegationInfo.totalPendingReward = BigNumber(
        delegationInfo.totalPendingReward.plus(
          validatorDelegationInfo.pendingReward
        )
      );
    }
  }

  delegationInfo.totalReward = delegationInfo.totalReward.toFixed(0);
  delegationInfo.totalDelegated = delegationInfo.totalDelegated.toFixed(0);
  delegationInfo.totalPendingReward =
    delegationInfo.totalPendingReward.toFixed(0);

  delegationInfo.validators = delegationInfo.validators.sort(
    (a, b) => b.delegatedAmount - a.delegatedAmount
  );

  return delegationInfo;
};

export async function getValidatorsInfo() {
  const url = "/api/validators?page=1&perPage=50";
  // const url = "https://staking-api.chiliz.com/validators?page=1&perPage=50"; // for local

  try {
    const response = await fetch(url);

    if (!response.ok) throw new Error("Could not fetch chz price");

    const data = await response.json();
    const validators = data?.validators;

    return validators || [];
  } catch (error) {
    console.error("Hata:", error.message);

    return [];
  }
}

const getDelegatorTransactions = async (delegator) => {
  const formattedDelegator = web3.utils.padLeft(delegator, 64).toLowerCase();
  const latestBlockNumber = Number(await getLastBlockNumber());
  const earliestBlockNumber = latestBlockNumber - 1728000;
  const gap = 50000;
  let transactions = [];

  for (let i = latestBlockNumber; i > earliestBlockNumber; i -= gap) {
    console.log("fetching stake actions", i, i - gap);

    const partOfUndelegations = await stakingContract.getPastEvents({
      fromBlock: toHex(i - gap),
      toBlock: toHex(i),
      address: stakingAddress,
      topics: [null, null, formattedDelegator],
    });

    transactions.push(...partOfUndelegations);
  }

  return transactions
    .map((u) => {
      const type = getTransactionType(u);
      let note = "";

      if (type === "undelegate") {
        const count = latestBlockNumber - Number(u.blockNumber);
        if (count > claimGap) {
          note = "You can claim or already claimed";
        } else {
          note = "You have to wait " + (claimGap - count) + " blocks for claim";
        }
      }

      return {
        amount: toHumanReadable(u.returnValues.amount.toString()),
        epoch: u.returnValues.epoch.toString(),
        validator: u.returnValues.validator,
        transactionHash: u.transactionHash,
        blockNumber: u.blockNumber.toString(),
        transactionIndex: u.transactionIndex.toString(),
        type: getTransactionType(u),
        note,
      };
    })
    .sort((a, b) => Number(b.blockNumber) - Number(a.blockNumber));
};

const getTransactionType = (log) => {
  let type = "unknown";

  switch (log.topics[0]) {
    case "0x30bcda2f188b532c7644e632473e83a6fb3c5c79717650d0ac790d141bb1b177":
      type = "delegate";
      break;
    case "0xa410e32157a44414a502bb47d775234de1aa7da123f5adfe426898f1601883fd":
      type = "undelegate";
      break;
    case "0xb22dec804803f8b1c5333f626cdbfdfb1bd629f1e1bb45dcfb22b5f74ed46b1c":
      type = "claim";
      break;
    case "0xa82f74002b6639f6cfc2cfd4f3ade1998108eda0f484d9064e3098c211e81d6e":
      type = "redelegate";
      break;

    default:
      break;
  }

  return type;
};

const getLastBlockNumber = async () => web3.eth.getBlockNumber();

const getDelegatedAmount = async ({ validator, delegator }) =>
  stakingContract.methods.getValidatorDelegation(validator, delegator).call();

const getPendingReward = async ({ validator, delegator }) =>
  stakingContract.methods.getPendingDelegatorFee(validator, delegator).call();

const getUnClaimedReward = async ({ validator, delegator }) =>
  stakingContract.methods
    .calcAvailableForRedelegateAmount(validator, delegator)
    .call();

const getDelegatorReward = async ({ validator, delegator }) =>
  stakingContract.methods.getDelegatorFee(validator, delegator).call();

const getCurrentEpoch = () => stakingContract.methods.currentEpoch().call();

const getDelegationInfo = async ({ validator, delegator }) => {
  const { delegatedAmount } = await getDelegatedAmount({
    validator,
    delegator,
  });

  const delegateRewards = await getDelegatorReward({ validator, delegator });
  const pendingReward = await getPendingReward({ validator, delegator });
  const unClaimedRewards = (await getUnClaimedReward({ validator, delegator }))
    .amountToStake;

  return {
    delegatedAmount: toHumanReadable(delegatedAmount.toString()),
    rewards: toHumanReadable(delegateRewards.toString()),
    unClaimedRewards: toHumanReadable(unClaimedRewards.toString()),
    pendingReward: toHumanReadable(pendingReward.toString()),
  };
};
